// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-airedu-js": () => import("./../../../src/pages/airedu.js" /* webpackChunkName: "component---src-pages-airedu-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-coming-js": () => import("./../../../src/pages/coming.js" /* webpackChunkName: "component---src-pages-coming-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ecological-alliance-js": () => import("./../../../src/pages/ecologicalAlliance.js" /* webpackChunkName: "component---src-pages-ecological-alliance-js" */),
  "component---src-pages-ecology-js": () => import("./../../../src/pages/ecology.js" /* webpackChunkName: "component---src-pages-ecology-js" */),
  "component---src-pages-engine-4-js": () => import("./../../../src/pages/engine4.js" /* webpackChunkName: "component---src-pages-engine-4-js" */),
  "component---src-pages-engine-js": () => import("./../../../src/pages/engine.js" /* webpackChunkName: "component---src-pages-engine-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-alliance-js": () => import("./../../../src/pages/industryAlliance.js" /* webpackChunkName: "component---src-pages-industry-alliance-js" */),
  "component---src-pages-industry-dynamics-js": () => import("./../../../src/pages/industryDynamics.js" /* webpackChunkName: "component---src-pages-industry-dynamics-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-permission-order-js": () => import("./../../../src/pages/permissionOrder.js" /* webpackChunkName: "component---src-pages-permission-order-js" */),
  "component---src-pages-ppt-js": () => import("./../../../src/pages/ppt.js" /* webpackChunkName: "component---src-pages-ppt-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-repos-js": () => import("./../../../src/pages/repos.js" /* webpackChunkName: "component---src-pages-repos-js" */),
  "component---src-pages-try-js": () => import("./../../../src/pages/try.js" /* webpackChunkName: "component---src-pages-try-js" */),
  "component---src-pages-video-4-js": () => import("./../../../src/pages/video4.js" /* webpackChunkName: "component---src-pages-video-4-js" */),
  "component---src-templates-application-scenario-js": () => import("./../../../src/templates/applicationScenario.js" /* webpackChunkName: "component---src-templates-application-scenario-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-releases-js": () => import("./../../../src/templates/releases.js" /* webpackChunkName: "component---src-templates-releases-js" */),
  "component---src-templates-repos-js": () => import("./../../../src/templates/repos.js" /* webpackChunkName: "component---src-templates-repos-js" */)
}

